* {

  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
  font-family: 'Poppins', sans-serif;

}





p {
  font-size: 18px;
}

.sedanfont {
  font-family: 'Sedan SC';
  font-style: normal;
  font-weight: 400;
  /* src: local('Sedan SC'), url('https://fonts.cdnfonts.com/s/15699/SedanSC-Regular.woff') format('woff'); */
}



/* Header css starts */

.dropdown-item:focus,
.dropdown-item:hover {
  background-color: white !important;
  color: #FFC827 !important;

}

#dropdown4 {
  margin-left: -250% !important;
  margin-left: -150% !important;

}

/* .dropdown:hover .dropdown-menu {
  display: block;
} */

.nav-item.dropdown:hover .dropdown-menu {
  display: block;
}

@media(max-width:992px) {
  #dropdown4 {
    margin-left: -20% !important;
    font-size: 14px;
    height: 40vh;
    overflow-y: scroll;
  }
}


#dropdown1 {
  margin-left: -100% !important;
}

@media(max-width:992px) {
  #dropdown1 {
    margin-left: -20% !important;
    font-size: 14px;
    height: 30vh;
    overflow-y: scroll;
  }
}

/* Header css ends */


/* Custom styles for rounded circle indicators */
.carousel-indicators.rounded-circle {
  justify-content: center;
  bottom: -20px;
  margin-top: 20px !important;

  /* Adjust vertical position as needed */
}

.carousel-indicators.rounded-circle li {
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  width: 12px;
  height: 12px;
  margin: 0 5px;
  /* margin-bottom: 3%; */
  /* Adjust spacing between indicators */
  transition: background-color 0.6s ease-in-out;
}

.carousel-indicators.rounded-circle .active {
  background-color: #ED1C24;
  width: 14px;
  height: 14px;
  margin: 0 5px;
  /* Adjust spacing for active indicator */
}



/* Scroll bar  starts*/
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-track {
  background-color: #D0D3D4;
}

::-webkit-scrollbar-thumb {
  background-color: #c4242c;
  border-radius: 2px;
}


/* Scroll bar  ends*/


/* what we do */
.image-container {
  position: relative;
}

/* .image-text {
  position: absolute;
  top: 70%;
  left: 50%;
  transform: translate(-50%, -50%);
} */


/* Global Impact */
.global-banner {
  background-image: url(../src/assests/globalcountry.png);
  background-size: cover;
}

@media (max-width: 425px) {
  .global-banner {
    background-image: url(../src/assests/GLOBAL\ IMPACT\ 3newmobile.png);
    background-size: cover;
    background-repeat: no-repeat;
    width: auto;
  }
}

/* Philipsection */
.philipsection {
  background-image: url(../src/assests/Background\ \(1\).png);
  background-size: cover;

}

/* Autobiography */
.bookphilip {
  background-image: url(../src/assests/philipbookbg.png);
  background-size: cover;
  background-repeat: no-repeat;
}


/* Where We work */
.india {
  background-image: url(../src/assests/pic1.png);
  background-size: cover;
  background-repeat: no-repeat;
  width: auto;
  /* background-position: left; */
}

@media (max-width: 424px) {
  .india {
    background-image: url(../src/assests/indiamobilebg.png);
    background-size: cover;
    background-position: bottom;
    background-repeat: no-repeat;
  }
}


.india-content {
  text-align: justify;
  overflow-y: scroll;
  height: 60vh;
  font-size: 20px;
}

.india-text {
  text-align: justify;
  font-size: 20px;
}

.india-text1 {
  text-align: left;
  font-size: 20px;
}

.counrties-text {
  font-size: 20px;
}



.india-global {
  background-image: url(../src/assests/india\ map.png);
  background-size: cover;
  background-position: center;
  width: auto;
}

@media (max-width: 424px) {
  .india-global {
    background-image: url(../src/assests/indiamobile.png);
    background-size: contain;
    background-repeat: no-repeat;
  }
}

.zimbabwe-global {
  background-image: url(../src/assests/zim\ map.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: auto;
}

.kenya {
  background-image: url(../src/assests/kenyabannernew.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  width: auto;

}

/* @media (max-width: 424px) {
  .kenya {
    background-image: url(../src/assests/kenyamobilebg.png);
    background-size: cover;
    width: 100%;
  }
} */

.kenya-global {
  background-image: url(../src/assests/kenya\ map.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: auto;

}


@media (max-width: 424px) {
  .kenya-global {
    background-image: url(../src/assests/kenyamobile.png);
    background-size: contain;
    background-repeat: no-repeat;
  }
}

.uganda {
  background-image: url(../src/assests/uganda.png);
  background-size: cover;
  background-repeat: no-repeat;
  width: auto;
  background-position: top;
}

.uganda-global {

  background-image: url(../src/assests/uganda\ map.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: auto;
}

@media (max-width: 424px) {
  .uganda-global {
    background-image: url(../src/assests/Ugandamobile.png);
    background-size: contain;
    background-repeat: no-repeat;
  }
}

.africa {
  background-image: url(../src/assests/africabanner1.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  width: auto;

}

@media (max-width: 424px) {
  .africa {
    background-image: url(../src/assests/africamobilebg.png);
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: 20%;
    padding: 0%;
    margin: 0%;
    width: auto;
  }
}
.aboutus {
  min-height: 100vh;
  background-image: url(../src/assests/aboutusbanner.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  width: auto;

}

.modelscroll::-webkit-scrollbar {
  width: 8px;
  height: 5px;
}

.modelscroll::-webkit-scrollbar-track {
  background-color: #D0D3D4;
}

.modelscroll::-webkit-scrollbar-thumb {
  background-color: #c4242c;
  border-radius: 5px;
}

.modelscroll {
  border-radius: 10px;
  height: 80vh;
  overflow-y: auto;
}


/* donate  css start*/

.donate {
  min-height: 100vh;
  background-image: url(../src/assests/donationbanernew.png);
  background-size: cover;
  background-position: center;
}

.donate {
  font-family: inherit;
}

.main-title {
  font-weight: 500;
}

.pressbanner {
  min-height: 100vh;
  background-image: url(../src/assests/pressbanner.jpg);
  background-size: cover;
  background-position: center;
}

.image-container {
  position: relative;
  text-align: center;
}

.image-text1 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.progress1 {
  width: 100px;
  height: 1px;
  border: 5px solid #ED1C24;
  border-radius: 6px;
}

.progress2 {
  width: 70px;
  height: 1px;
  border: 5px solid #099BAA;
  border-radius: 6px;
}

.progress3 {
  width: 50px;
  height: 1px;
  border: 5px solid #3C3C3C;
  border-radius: 6px;
}

.progress4 {
  width: 30px;
  height: 1px;
  border: 5px solid #000;
  border-radius: 6px;
}


/* donate  css ends*/






/* Philipsection */
.philipsection {
  background-image: url(../src/assests/Background\ \(1\).png);
  background-size: cover;

}

/* Autobiography */
.bookphilip {
  background-image: url(../src/assests/philipbookbg.png);
  background-size: cover;
  background-repeat: no-repeat;


}



/* ZIMBABWE css start */
.ZIMBABWE {
  min-height: 100vh;
  width: auto;
  background-image: url(../src/assests/Zimbabwe123.png);
  background-size: cover;
  background-position: center;
}

/* ZIMBABWE css ends */

@media (max-width: 424px) {
  .zimbabwe-global {
    background-image: url(../src/assests/zimbabwemobile.png);
    background-size: contain;
    background-repeat: no-repeat;
  }
}

/* USA css start */
.usa {
  min-height: 100vh;
  width: auto;
  background-image: url(../src/assests/usabanner1.png);
  background-size: cover;
  background-position: center;
}



.usa-global {

  background-image: url(../src/assests/usa\ map.png);
  background-size: cover;
  width: auto;

  background-position: center;

}

/* USA css ends */

@media (max-width: 424px) {
  .usa-global {
    background-image: url(../src/assests/USAmobile.png);
    background-size: contain;
    background-repeat: no-repeat;
  }
}

/* founder css start */
.founder {
  min-height: 100vh;
  width: 100%;
  background-image: url(../src/assests/Foundersbanner.jpg);
  background-size: cover;
  background-repeat: no-repeat;
}

.founderbg1 {
  background-image: url(../src/assests/founderbg1.png);
  background-size: cover;
}

/* founder css ends */



/* Director css start */
.Director {
  min-height: 100vh;
  width: auto;
  background-image: url(../src/assests/Director.png);
  background-size: cover;
  background-position: left;
}

/* Director css ends */



/* Donation card css */
/* Variables */
/** {
  box-sizing: border-box;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}*/

/* Firefox */
/*input[type=number] {
  -moz-appearance: textfield;
}
body {
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  display: flex;
  justify-content: center;
  align-content: center;
  height: 100vh;
  width: 100vw;
  margin: 0;
  padding: 0;
}
*/
/*form {
  width: 30vw;
  min-width: 500px;
  align-self: center;
  box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1),
    0px 2px 5px 0px rgba(50, 50, 93, 0.1), 0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
  border-radius: 7px;
  
}*/

input,
select {
  border-radius: 6px;
  margin-bottom: 6px;
  padding: 12px;
  border: 1px solid rgba(50, 50, 93, 0.1);
  height: 44px;
  font-size: 16px;
  width: 100%;
  background: white;
}

.result-message {
  line-height: 22px;
  font-size: 16px;
}

.result-message a {
  color: rgb(89, 111, 214);
  font-weight: 600;
  text-decoration: none;
}

.hidden {
  display: none !important;
}

.wd-2 {
  width: 50%;
}

#card-error {
  color: rgb(105, 115, 134);
  text-align: left;
  font-size: 13px;
  line-height: 17px;
  margin-top: 12px;
}

#card-element,
#card-element1 {
  border-radius: 4px 4px 0 0;
  padding: 12px;
  border: 1px solid rgba(50, 50, 93, 0.1);
  height: 44px;
  width: 100%;
  background: white;
}

#payment-request-button {
  margin-bottom: 32px;
}

/* Buttons and links */
/*button {
    background: #5469d4;
    color: #ffffff;
    font-family: Arial, sans-serif;
    border-radius: 0 0 4px 4px;
    border: 0;
    padding: 1rem 2.25rem !important;
    padding: 12px 16px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    display: block;
    transition: all 0.2s ease;
    box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
    width: 100%;
  }*/
button:hover {
  filter: contrast(115%);
}

button:disabled {
  opacity: 0.5;
  cursor: default;
  margin: auto;
}

/* spinner/processing state, errors */
/*.spinner,
  .spinner:before,
  .spinner:after {
    border-radius: 50%;
  }
  .spinner {
    color: #ffffff;
    font-size: 22px;
    text-indent: -99999px;
    margin: 0px auto;
    position: relative;
    width: 20px;
    height: 20px;
    box-shadow: inset 0 0 0 2px;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
  }
  .spinner:before,
  .spinner:after {
    position: absolute;
    content: "";
  }
  .spinner:before {
    width: 10.4px;
    height: 20.4px;
    background: #036;
    border-radius: 20.4px 0 0 20.4px;
    top: -0.2px;
    left: -0.2px;
    -webkit-transform-origin: 10.4px 10.2px;
    transform-origin: 10.4px 10.2px;
    -webkit-animation: loading 2s infinite ease 1.5s;
    animation: loading 2s infinite ease 1.5s;
  }
  .spinner:after {
    width: 10.4px;
    height: 10.2px;
    background: #036;
    border-radius: 0 10.2px 10.2px 0;
    top: -0.1px;
    left: 10.2px;
    -webkit-transform-origin: 0px 10.2px;
    transform-origin: 0px 10.2px;
    -webkit-animation: loading 2s infinite ease;
    animation: loading 2s infinite ease;
  }
  */


/* custom design*/
.uppercase {
  text-transform: uppercase !important;
}

/* #sel{
    top: 7%;
    position: relative;
    width: 100%;
    z-index: 999;
  } */

#payment-form,
#payment-form1 {
  top: 10%;
  position: relative;
}

.text-center {
  text-align: center !important;
}

/* .donation-subscription{
    position: absolute;
    left: 0;
    right: 0;
    top: -60px;
  } */

.donation-subscription a {
  height: 34px;
  line-height: 30px;
  float: left;
  width: 50%;
  color: #036;
  background: transparent;
  border: 1px solid #dfdfd7;
  left: 0;
  right: 0;
  top: -20;
}


.donation-subscription a.active {
  color: white;
  background-color: #000;
}

.donation-subscription a:hover {
  cursor: pointer;
}

.donation-subscription a:first-child {
  border-radius: 4px 0 0 4px;
}

.donation-subscription a:last-child {
  border-radius: 0 4px 4px 0;
}


.form-header {
  /* background: #f4f5f0; */
  /* border-bottom: 1px solid #dfdfd7; */
  /* border-top-left-radius: 3px; */
  /* border-top-right-radius: 3px; */
  color: #333232;
  display: block;
  font-size: 18px;
  font-weight: 500;
  /* padding: 10px 10px; */
}

.amount-top {
  color: #333232;
  line-height: 4;
}

.amount-body {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.button {
  height: 48px;
  line-height: 48px;
  background-color: #CD2026;
  transition: background-color .15s linear;
  text-transform: uppercase;
  letter-spacing: .1em;
  border-radius: 4px;
  border: 0;
  font-size: 15px;
  /* padding: 5px; */
  font-weight: 500;
  display: inline-block;
  color: #fff;
  text-decoration: none;
}

.button.button--grey-20 {
  height: 48px;
  line-height: 48px;
  background-color: #e9eae4;
  color: #71716c;
  transition: background-color .15s linear;
}

.amount-body .button.amt-btn,
.amount-body .button.amt-btn1 {
  text-transform: lowercase;
  width: calc((100% - 20px)/3);
}

.amount-body .button.other-amt-btn,
.amount-body .other-button.donate-amt,
.amount-body .button.other-amt-btn1,
.amount-body .other-button.donate-amt1 {
  width: calc((200% - 14px)/3);
}

.amount-body .button.amt-btn,
.amount-body .button.other-amt-btn,
.amount-body .button.amt-btn1,
.amount-body .button.other-amt-btn1 {
  border: 1px solid #dfdfd7;
  color: #9b9b94;
  letter-spacing: 0;
  padding: 0;
}

.amount-body .button.amt-btn.selected,
.amount-body .button.amt-btn1.selected {
  background-color: #000;
  /* border-color: #CD2026; */
  color: #fff;
}

.amount-body .button.amt-btn:hover,
.amount-body .button.amt-btn1:hover {
  cursor: pointer;
}

.input-container {
  position: relative;
}

.pre {
  left: 0;
}

.post {
  right: 0;
}

.pre,
.post {
  position: absolute;
  top: 50%;
  z-index: 2;
  transform: translateY(-50%);
}

.amount-body .pre,
.amount-body .post {
  color: #9b9b94;
}

.post,
.pre {
  font-weight: 500;
  font-size: .8rem;
  line-height: 1rem;
  color: #c1c1ba;
  padding: 0 10px;
}

.post~input,
.pre~input {
  padding-left: 30px !important;
}

.amount-body .pre,
.amount-body .post {
  font-size: 16px;
}

.amount-body .other-button.input-container input {
  font-size: 16px;
  height: 48px;
}

.edit-amt {
  text-decoration: underline;
  color: #036;
  font-weight: 500;
  transition: color .15s linear;
  -webkit-tap-highlight-color: rgba(0, 0, 0, .1);
  outline: none;
}

.edit-amt:hover {
  cursor: pointer;
  color: #004d99;
}


@media(max-width: 780px) {
  .amt-name {
    display: block !important;
  }

  /* paypal */
  #paypalclick {
    top: 30%;
  }


  /* .modal-content { */
  /* position: relative; */
  /* display: -ms-flexbox; */
  /* display: flex; */
  /* -ms-flex-direction: column; */
  /* flex-direction: column; */
  /* width: 75% !important; */
  /* margin-left: 109px !important; */
  /* pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: 0.3rem;
    outline: 0;
  } */

  .wd-2 {
    width: 100%;
  }
}

/* .amt-name{
    display: flex ;
  } */
.amt-fname {
  width: 100%;
}

.amt-email {
  width: 100%;
}

.mb1 {
  margin-top: 25px;
}

.edit-dtl {
  background-color: #f7f7f7;
  border-radius: 4px;
  border: 1px solid #dfdfd7;
  color: #71716c;
  cursor: pointer;
  height: 40px;
  margin-bottom: 16px;
  text-align: left;
  width: 100%;
}

.edit-dtl span {
  line-height: 60px;
  margin-left: 30px;
  position: relative;
}

.edit-dtl span::before {
  color: #c1c1ba;
  content: " \003C";
  display: block;
  font-size: 24px;
  left: -20px;
  position: absolute;
  top: -23px;
}

.ed-1 {
  font-size: 14px;
  top: -12px;
}

/* Contact us starts */
.title h4:before {
  content: "";
  position: absolute;
  background: red;
  height: 3px;
  width: 100%;
  bottom: -5px;
}

.title {
  width: fit-content;
}

/* Contact us ends */


/* loader */
/*#loading {
    position: fixed;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    text-align: center;
    opacity: 0.7;
    background-color: #000000;
    z-index: 9999;
  }
  .loader {
    top: 50%;
    font-size: 10px;
    margin: 50px auto;
    text-indent: -9999em;
    width: 11em;
    height: 11em;
    border-radius: 50%;
    background: #ffffff;
    background: -moz-linear-gradient(left, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
    background: -webkit-linear-gradient(left, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
    background: -o-linear-gradient(left, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
    background: -ms-linear-gradient(left, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
    background: linear-gradient(to right, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
    position: relative;
    -webkit-animation: load3 1.4s infinite linear;
    animation: load3 1.4s infinite linear;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
  }
  .loader:before {
    width: 50%;
    height: 50%;
    background: #ffffff;
    border-radius: 100% 0 0 0;
    position: absolute;
    top: 0;
    left: 0;
    content: '';
  }
  .loader:after {
    background: #000000;
    width: 75%;
    height: 75%;
    border-radius: 50%;
    content: '';
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
  @-webkit-keyframes load3 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes load3 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  
  /* loader end */
/*@-webkit-keyframes loading {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes loading {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  */
/* paypal */
@media (min-width:760px) {
  #paypalclick {
    top: 76%;
  }
}


/* Donation card css ends */



/* Footer  css starts*/
.fa-facebook:hover {
  background-color: #3b5998;
}

.fa-x-twitter:hover {
  background-color: #000;
}

.linkedinfooter:hover {
  background-color: #0a66c2;
}

.fa-instagram:hover {
  background: linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
}

.fa-youtube:hover {
  background-color: brown;
}

.text {
  position: relative;
}

.text:hover {
  right: -5px;
}



/* Footer css ends */

/* .fa-instagram {
  background: linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
} */


/* Global Team starts */
.globeteam {
  background-image: url(../src/assests/globalteambanner.png);
  background-size: cover;
  min-height: 100vh;
}

@media (max-width: 424px) {
  .globeteam {
    background-image: url(../src/assests/globalteammobile.png);
    background-size: contain;
    background-repeat: no-repeat;
  }
}

/* Global Team ends */

/* Volunteers Team starts */
.Volunteers {
  background-image: url(../src/assests/eventvolunteerbanner.png);
  background-size: cover;
  min-height: 100vh;
  width: auto;
  background-position: center;

}

/* Volunteers Team ends */

/* Medical camps starts */
.mcamps {
  min-height: 100vh;
  background-image: url(../src/assests/medicalcampsbanner.png);
  background-size: cover;
  background-position: top;
}

/* Medical camps ends */
/* Student Team starts */
.Student {
  background-image: url(../src/assests/studentbanner.png);
  background-size: cover;
  min-height: 100vh;
  width: auto;
  background-position: center;

}

/* Student Team ends */



/* Susan Blogs Starts  */
/* .blogs1bg{
  background-image: url(../src/assests/philipwhitebg.png);
  background-size: cover;
  background-repeat: no-repeat;

} */

.blogsbg {
  background-image: url(../src/assests/blogsbg.png);
  background-size: cover;
  background-repeat: no-repeat;
}

/* Susan Blogs ends  */



/* sponser css start */
.Sponser {
  min-height: 100vh;
  width: 100%;
  background-image: url(../src/assests/sponcorsnew.png);
  background-size: cover;
  background-position: top;
}

@media (max-width: 424px) {
  .Sponser {
    background-image: url(../src/assests/sponsornewmobile.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: top;
    min-height: 105vh;

  }
}

.founders {
  border-radius: 20% !important;
}

.philipshomebg {
  background-image: url(../src/assests/homephilipsbg.png);
  background-size: cover;

}


.btncards:focus {
  outline: none;
  box-shadow: none;
}

/* sponser css ends */

/* sponser2 css start */
.Sponser2 {
  min-height: 100vh;
  width: 100%;
  background-image: url(../src/assests/sponsorurl2.png);
  background-size: cover;
  background-position: top;

}




/* sponser2 css ends */

/* sponser3 css start */
.Sponser3 {
  min-height: 100vh;
  width: 100%;
  background-image: url(../src/assests/Sponserurl3.png);
  background-size: cover;
  background-position: top;
}

/* sponser3 css ends */

/* Eventfather css start */
.Eventfather {
  min-height: 100vh;
  width: 100%;
  background-image: url(../src/assests/FatherDaughterbanner.png);
  background-size: cover;
  background-repeat: no-repeat;
}

.fddhomeimg {
  border-top-right-radius: 7px !important;
  border-top-left-radius: 7px !important;

}

@media (max-width: 424px) {
  .Eventfather {
    min-height: 100vh;
    background-image: url(../src/assests/fddhomeevent.png);
    background-size: contain;
    background-position: center;
  }
}



/* Eventfather css ends */


/* Eventpeople css start */
.Eventpeople {
  min-height: 100vh;
  width: auto;
  background-image: url(../src/assests/peoplenotproductbanner.png);
  background-size: cover;
  background-position: center;
}

@media (max-width: 424px) {
  .Eventpeople {
    background-image: url(../src/assests/peoplwmobile.jpg);
    background-size: contain;
    background-repeat: no-repeat;
  }
}

/* Eventpeople css ends */


/* Craniofacial  starts*/
.craniofacial-bg {
  background-image: url(../src/assests/craniobanner.png);
  background-size: cover;
  width: auto;
  background-position: top;
}

/* Event5krun css start */
.Event5krun {
  min-height: 100vh;
  width: auto;
  background-image: url(../src/assests/5kEvents\ Banner.png);
  background-size: cover;
  background-position: center;
}

.SmilewithSanta {
  background-image: url(../src/assests/santasmilebannerbg.jpeg);
  background-size: cover;
  width: auto;
  background-repeat: no-repeat;
  background-position: center;

}



@media (max-width: 768px) {
  .santabutton {
    left: 50px !important;
    top: 300px !important;
  }
}



@media (max-width: 425px) {
  .SmilewithSanta {
    background-image: url(../src/assests/santasmilebg.jpeg);
    background-size: cover;
    background-repeat: no-repeat;
    margin-top: 10%;
    width: auto;
    background-position: center;
  }
}

/* Event5krun css ends */


/* Human Trafficking  starts*/
.humanbg {
  background-image: url(../src/assests/humanbanner.png);
  min-height: 100vh;
  background-size: cover;
  background-position: top;
  width: auto;
}

/* Human Trafficking  ends*/
.homepageslider {
  /* background: linear-gradient(180deg, #161E32 0%, #435B98 54.5%, #161E32 100%); */
  background: linear-gradient(180deg, #1A1E27 0%, #5E6C8D 51.79%, #1A1E27 100%);

}

/*  Vehicle starts*/
.Vehicle {
  background-image: url(../src/assests/vehiclebanner.png);
  min-height: 100vh;
  background-size: cover;
  background-position: center;
  width: auto;
}

/* Vehicle  ends*/

/*  PersonalProperty starts*/
.PersonalProperty {
  background-image: url(../src/assests/propertybanner.png);
  min-height: 100vh;
  background-size: cover;
  background-position: center;
  width: auto;
}

/* @media (max-width: 424px) {
  .PersonalProperty{
    background-image: url(../src/assests/property\ donationmobile.png);
    background-size: contain;
    background-repeat: no-repeat;
  }
} */


/* PersonalProperty  ends*/

/* Medical Starts */
.inkind {
  min-height: 100vh;
  background-image: url(../src/assests/inkind.png);
  background-size: cover;
  width: auto;
  background-position: center;
}



/* Medical Ends */

/* Gifts Starts */
.legacygiftsbg {
  min-height: 100vh;
  background-image: url(../src/assests/legacygiftsbanner.png);
  background-size: cover;
  background-position: center;
  width: auto;


  /*  */
}


/* accordion */
.accordion-button {
  background-color: #fff !important;
}

.accordion-button:focus {
  box-shadow: none;
}

.accordion-button:not(.collapsed) {
  color: #212529;
}



/* Innovative solutions starts */
.innovativebg {
  background-image: url(../src/assests/innovativebanner.png);
  background-size: cover;
  background-repeat: no-repeat;
}

.innovativebg1 {
  background-image: url(../src/assests/innovativebg1.png);
  background-size: cover;
  background-repeat: no-repeat;
}

/* Camps1 starts */
.camps1bg {
  background-image: url(../src/assests/camps1bg.png);
  min-height: 100vh;
  width: auto;
  background-position: left;
  background-size: cover;

}

@media (max-width: 424px) {
  .camps1bg {
    background-image: url(../src/assests/upcomingmobile.png);
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 100vh;
    width: auto;

  }
}



/* Camps1 ends */


/* Innovative solutions ends */

/* events Starts */
.Events {
  min-height: 100vh;
  background-image: url(../src/assests/santasmilebannerbg.jpeg);
  background-size: cover;
  background-position: center;
  width: auto;
}

/* @media (max-width: 768px) {
  .Events {
    background-image: url(../src/assests/eventsmobile.png);
    background-size: contain;
    background-repeat: no-repeat;
  }
} */

/* events Ends */

/* leader Starts */
.leader {
  min-height: 100vh;
  background-image: url(../src/assests/leaderurl.png);
  background-size: cover;
}

/* leader Ends */

.dropdown:hover .dropdown-menu {
  display: block;



}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/* south map */
.south-global {
  background-image: url(../src/assests/southmap.png);
  background-size: cover;
  background-position: center;
  width: auto;
}

/* south map */
@media (max-width: 424px) {
  .south-global {
    background-image: url(../src/assests/South\ Africamobile.png);
    background-size: contain;
    background-repeat: no-repeat;
  }
}

.pressurl {
  min-height: 100vh;
  background-image: url(../src/assests/event5kimg2.png);
  background-size: cover;
  background-position: center;
  width: auto;
}

.campsection {
  background: linear-gradient(180deg, rgba(9, 155, 170, 0) 0%, #099BAA 150%);
  min-height: 90vh;

}

.newsimg {
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;

}

.Donation-usa {
  background-image: url(../src/assests/Donationindiabg.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: right;
  min-height: 100vh;
}

.Donation-india {
  background-image: url(../src/assests/Donationindiabg.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: right;
  min-height: 100vh;
}

.Donation-kenya {
  background-image: url(../src/assests/Donation-kenya.png);
  background-size: cover;
  min-height: 100vh;
}

.Donation-africa {
  background-image: url(../src/assests/africadonationbanner.png);
  background-size: cover;
  min-height: 100vh;
}

/* home page card Linear Gradient */

/* home page slider bg */
.linear-gradient {
  background: linear-gradient(180deg, #161E32 0%, #435B98 54.5%, #161E32 100%);

}

.linear-gradientfooter {

  background: linear-gradient(180deg, #1A1E27 0%, #5E6C8D 54.5%, #1A1E27 100%);


}
.popup-container {
  /* position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center; */
  /* z-index: 9999; */
   /* Ensure it appears above other content */
}

.popup-content {
  position: relative;
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  max-width: 80%;
}

.btn-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 16px;
  color: #333;
}

.popup-image {
  max-width: 100%;
  height: auto;
  object-fit: cover;
}
.custom-underline {
  position: relative; 
  display: inline-block; 
 

}

.custom-underline::after {
  content: ""; 
  position: absolute; 
  left: 50%; 
  transform: translateX(-50%); 
  bottom: -5px; 
  width: 100%; 
  height: 2px;
  background-color: red; 
}